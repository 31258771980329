import { render } from "./ScopePicker.vue?vue&type=template&id=648678c7&scoped=true"
import script from "./ScopePicker.vue?vue&type=script&lang=js"
export * from "./ScopePicker.vue?vue&type=script&lang=js"

import "./ScopePicker.vue?vue&type=style&index=0&id=648678c7&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-648678c7"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QBar,QSpace,QBtn,QCardSection,QList,QItem,QSeparator,QCardActions,QBtnDropdown,QItemSection,QItemLabel});qInstall(script, 'directives', {ClosePopup,Ripple});
