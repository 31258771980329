<template>
  <div style="padding: 3px">
    <!-- Field Picker Window -->
    <q-dialog v-model="layout" persistent class="font-nunito">
      <q-card style="width: 700px; max-width: 80vw">
        <q-bar class="bg-secondary text-white text-bold">
          <div class="text-subtitle1">Выбор блоков</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup></q-btn>
        </q-bar>

        <!-- Denomination Output -->
        <q-card-section class="q-pt-sm">
          <q-bar
            dense
            class="q-mt-sm bg-secondary text-white text-subtitle2"
            style="border-radius: 2px"
          >
            <div>Вывод наименований</div>
          </q-bar>
          <div class="q-pt-sm">
            <ul class="ul-block">
              <q-list dense separator>
                <li
                  v-for="element in fields.denomination"
                  :key="element"
                  class="q-mb-none"
                >
                  <q-item
                    class="q-pa-none q-mb-none q-pt-sm q-pl-sm rounded"
                    style="width: 100%; font-widght: bold; border-radius: 2px"
                    :class="filedStyle(element.isPicked)"
                    @click="toggleField('deno', element)"
                    clickable
                    v-ripple
                    dense
                  >
                    <small style="font-size: 9pt">
                      {{ element.name }}
                    </small>
                  </q-item>
                  <q-separator />
                </li>
              </q-list>
            </ul>
          </div>
        </q-card-section>

        <!-- Calculation Fields Output -->
        <q-card-section class="q-py-none">
          <q-bar
            dense
            class="q-mt-sm bg-secondary text-white text-subtitle2"
            style="border-radius: 2px"
          >
            Вычисляемые поля
          </q-bar>
          <div class="q-pt-sm">
            <ul class="ul-block">
              <q-list dense separator>
                <li
                  v-for="element in fields.calculated"
                  :key="element"
                  class="q-mb-none"
                >
                  <q-item
                    dense
                    clickable
                    v-ripple
                    class="q-pa-none q-mb-none q-pt-sm q-pl-sm rounded"
                    style="width: 100%; font-widght: bold; border-radius: 3px"
                    :class="filedStyle(element.isPicked)"
                    @click="toggleField('calc', element)"
                  >
                    <small style="font-size: 9pt">
                      {{ element.name }}
                    </small>
                  </q-item>
                  <q-separator />
                </li>
              </q-list>
            </ul>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn
            flat
            label="Применить"
            class="text-weight-bold"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Draggble denomination buttons -->
    <div style="display: block">
      <q-bar
        dense
        class="q-mt-sm borders-round bg-teal-2 text-teal-9"
        style="border-radius: 2px"
      >
        Вывод наименований
      </q-bar>

      <div style="padding: 5px 5px 0 5px; display: inline-block; float: left">
        <q-btn round push color="secondary" @click="layout = true">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </q-btn>
      </div>

      <draggable
        tag="transition-group"
        style="padding-top: 5px"
        :component-data="{
          tag: 'div',
          type: 'transition-group',
          name: !drag ? 'flip-list' : null,
        }"
        v-model="denoDraggbleList"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        item-key="order"
      >
        <template #item="{ element }">
          <div style="display: inline-block; margin: 3px 3px 10px 3px">
            <q-btn
              v-if="element.data.dropList === null"
              class="text-bold"
              :class="buttonStyle(element.data.isPicked)"
              @click="toggleButton(element)"
              push
              no-caps
            >
              {{ element.data.name }}
              <i
                v-if="element.data.dropList !== null"
                class="fa fa-chevron-down q-pl-md"
                aria-hidden="true"
              ></i>
            </q-btn>
            <q-btn-dropdown
              v-else
              :class="buttonStyle(element.data.isPicked)"
              :label="optionName(element)"
              dropdown-icon="expand_more"
              class="text-weight-bold"
              no-caps
              push
            >
              <q-list class="font-nunito text-teal-10">
                <q-item
                  v-for="value in element.data.dropList.options"
                  @click="selectOption(value.id, element.data)"
                  :class="[ element.data.dropList.option === value.id ? 'bg-teal-2' : '']"
                  :key="value.id"
                  v-close-popup
                  clickable
                >
                  <q-item-section>
                    <q-item-label>{{ value.name }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
        </template>
      </draggable>
    </div>

    <!-- Draggble calculated buttons -->
    <div style="display: block">
      <q-bar
        dense
        class="borders-round bg-teal-2 text-teal-9"
        style="border-radius: 2px"
      >
        Вычисляемые поля
      </q-bar>

      <draggable
        tag="transition-group"
        style="padding-top: 5px"
        :component-data="{
          tag: 'div',
          type: 'transition-group',
          name: !drag ? 'flip-list' : null,
        }"
        v-model="calcDraggbleList"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        item-key="order"
      >
        <template #item="{ element }">
          <div style="display: inline-block; margin: 3px 3px 10px 3px">
            <q-btn
              class="text-bold"
              :class="buttonStyle(element.data.isPicked)"
              @click="
                element.data.dropList !== null ? null : toggleButton(element)
              "
              push
              no-caps
            >
              {{ element.data.name }}
              <i
                v-if="element.data.dropList !== null"
                class="fa fa-chevron-down q-pl-md"
                aria-hidden="true"
              ></i>
            </q-btn>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { ref, computed } from "vue";

const fields = {
  denomination: [
    { id: 0, dropList: null, isPicked: false, name: "Производитель" },
    { id: 1, dropList: null, isPicked: false, name: "Регионы" },
    { id: 2, dropList: null, isPicked: false, name: "Корпорация" },
    { id: 3, dropList: null, isPicked: false, name: "Страна" },
    { id: 4, dropList: null, isPicked: false, name: "Категория" },
    { id: 5, dropList: null, isPicked: false, name: "Бренд" },
    { id: 6, dropList: null, isPicked: false, name: "Торговое наименование" },
    {
      id: 7,
      dropList: null,
      isPicked: true,
      name: "Полное торговое наименование",
    },
    { id: 8, dropList: null, isPicked: false, name: "Лекарственная форма" },
    { id: 9, dropList: null, isPicked: false, name: "Дозировка" },
    { id: 10, dropList: null, isPicked: false, name: "Номер" },
    { id: 11, dropList: null, isPicked: false, name: "Rx/OTC" },
    { id: 12, dropList: null, isPicked: false, name: "Original/Generic" },
    { id: 13, dropList: null, isPicked: false, name: "Входит в формуляр ЛС" },
    {
      id: 14,
      dropList: {
        option: 0,
        options: [
          { id: 0, name: "Нет" },
          { id: 1, name: "ATC1" },
          { id: 2, name: "ATC2" },
          { id: 3, name: "ATC3" },
          { id: 4, name: "ATC4" },
          { id: 5, name: "ATC5" },
        ],
      },
      isPicked: true,
      name: "Код АТС",
    },
    {
      id: 15,
      dropList: {
        option: 0,
        options: [
          { id: 0, name: "Нет" },
          { id: 1, name: "ATC" },
          { id: 2, name: "ATC3" },
          { id: 3, name: "ATC4" },
        ],
      },
      isPicked: true,
      name: "ATC EphMRA",
    },
    {
      id: 16,
      dropList: {
        option: 0,
        options: [
          { id: 0, name: "Нет" },
          { id: 1, name: "МКБ" },
          { id: 2, name: "МКБ1" },
          { id: 3, name: "МКБ2" },
          { id: 4, name: "МКБ3" },
        ],
      },
      isPicked: true,
      name: "МКБ",
    },
    { id: 17, dropList: null, isPicked: false, name: "МНН" },
    { id: 18, dropList: null, isPicked: false, name: "Район" },
    { id: 19, dropList: null, isPicked: false, name: "Брик" },
    { id: 20, dropList: null, isPicked: false, name: "Город" },
  ],
  calculated: [
    { id: 0, dropList: null, isPicked: true, name: "Сумма" },
    { id: 1, dropList: null, isPicked: true, name: "Упаковки" },
    { id: 2, dropList: null, isPicked: false, name: "Минимальная упаковка" },
    { id: 3, dropList: null, isPicked: false, name: "Прирост: сумма" },
    { id: 4, dropList: null, isPicked: false, name: "Прирост: упаковки" },
    { id: 5, dropList: null, isPicked: false, name: "EL: сумма" },
    { id: 6, dropList: null, isPicked: false, name: "EL: упаковки" },
    { id: 7, dropList: null, isPicked: false, name: "Доля: сумма" },
    { id: 8, dropList: null, isPicked: false, name: "Доля: упаковки" },
    { id: 9, dropList: null, isPicked: false, name: "Рейтинг сумма" },
    { id: 10, dropList: null, isPicked: false, name: "Средняя цена" },
    { id: 11, dropList: null, isPicked: false, name: "Средняя цена за ед." },
    {
      id: 12,
      dropList: null,
      isPicked: false,
      name: "Индекс изменения цены, %",
    },
    { id: 13, dropList: null, isPicked: false, name: "Рейтинг упаковки" },
    { id: 14, dropList: null, isPicked: false, name: "Индекс дистрибуции, %" },
  ],
};

export default {
  components: {
    draggable,
  },
  setup() {
    const moreContent = ref(true);

    return {
      layout: ref(false),

      moreContent,
      contentSize: computed(() => (moreContent.value ? 5 : 5)),
      drawer: ref(false),
      drawerR: ref(false),
    };
  },
  data() {
    const denominationList = [];
    const calculatedList = [];

    return {
      fields: fields,
      denoDraggbleList: denominationList.map((data, index) => {
        return { data, order: index + 1 };
      }),
      calcDraggbleList: calculatedList.map((data, index) => {
        return { data, order: index + 1 };
      }),
      drag: false,
    };
  },
  created() {
    for (const key in fields.denomination) {
      if (fields.denomination[key].isPicked) {
        this.appendField(this.denoDraggbleList, fields.denomination[key]);
      }
    }

    for (const key in fields.calculated) {
      if (fields.calculated[key].isPicked) {
        this.appendField(this.calcDraggbleList, fields.calculated[key]);
      }
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    buttonStyle(isPicked) {
      return isPicked ? "text-white bg-red-12" : "text-white bg-secondary";
    },
    filedStyle(isPicked) {
      return isPicked ? "text-white bg-red-12" : "text-teal-10 bg-white";
    },
    toggleButton(el) {
      el.data.isPicked = !el.data.isPicked;
    },
    selectOption(id, elementData) {
      elementData.dropList.option = id;
      elementData.isPicked = id !== 0;
    },
    optionName(el) {
      var id = el.data.dropList.option;
      var option = el.data.dropList.options.find((e) => e.id === id);
      var optionName = option !== undefined ? option.name : "Нет"

      return el.data.name + " [ " + optionName + " ]";
    },
    appendField(operableTable, el) {
      if (!operableTable.some((e) => e.data.id === el.id)) {
        var copiedData = JSON.stringify({ data: el, order: 1 });
        var fieldData = JSON.parse(copiedData);
        fieldData.data.isPicked = false;
        operableTable.push(fieldData);
      }
    },
    removeField(operableTable, el) {
      for (const key in operableTable) {
        if (operableTable[key].data.id === el.id) {
          operableTable.splice(key, 1);
          break;
        }
      }
    },
    toggleField(tableType, el) {
      el.isPicked = !el.isPicked;

      var operableTable =
        tableType === "deno"
          ? this.denoDraggbleList
          : tableType === "calc"
          ? this.calcDraggbleList
          : null;

      if (operableTable === null) {
        throw "Invalid TableType value at ToggleField!";
      }

      if (el.isPicked) {
        this.appendField(operableTable, el);
      } else {
        this.removeField(operableTable, el);
      }
    },
  },
};
</script>

<style scoped>
.ul-block {
  list-style-type: none;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  padding: 0;
  margin: 0;
}
</style>
