<template>
  <q-layout class="font-nunito">
    <q-header elevated class="bg-secondary">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />
        <q-toolbar-title> Scope Picker v0.0.1 </q-toolbar-title>

        <div>Quasar</div>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <scope-picker></scope-picker>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import ScopePicker from "./components/ScopePicker.vue";

export default {
  name: "LayoutDefault",

  components: {
    ScopePicker,
  },

  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;800&display=swap');

.font-roboto {
  font-family: "Roboto", sans-serif !important;
}

.font-nunito {
  font-family: "Nunito", sans-serif !important;
}
</style>
